import React from 'react';
import logo from './logo.svg';
import './App.css';
import Posts from './components/Post';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>cph.today</h1>
      </header>
      <Posts/>
      <footer>
        Disclaimer: The information on this website is aggregated from social media platforms and processed and translated by ChatGPT. While we strive to provide accurate and up-to-date information, the nature of data aggregation and machine processing means that the content may not always be reliable. We encourage you to refer to the original posts linked in our content for the most accurate source of information.
      </footer>
    </div>
  );
}

export default App;
