import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Post } from '../types/Post';
import { format, isToday, isTomorrow, parse } from 'date-fns';

function formatDate(dateStr: string): string {
    const date = parse(dateStr, 'dd/MM/yyyy', new Date());

    if (isToday(date)) {
        return 'Today';
    } else if (isTomorrow(date)) {
        return 'Tomorrow';
    } else {
        // Format e.g., "July 6" - omitting the year
        return format(date, 'MMMM d');
    }
}

const Posts: React.FC = () => {
    const [posts, setPosts] = useState<Post[]>([]);

    useEffect(() => {
        axios.get<Post[]>('https://pp8wnh2a6i.execute-api.eu-north-1.amazonaws.com/prod/posts')
            .then(response => {
                console.log('Data received:', response.data);
                setPosts(response.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                console.error('Error response:', error.response);
            });
    }, []);

    return (
        <div>
            {posts.map((post, index) => (
                <a href={post.url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <div key={index} className="post-container">
                        <p className="date-info">
                            {formatDate(post.start_date)}
                            {post.end_date && <span> until {formatDate(post.end_date)}</span>}
                        </p>
                        <h2>{post.title}</h2>
                        <p className="ig-username">@{post.ig_username}</p>
                        {post.description && <p className="post-description">{post.description}</p>}
                    </div>
                </a>
            ))}
        </div>
    );
};

export default Posts;
